import { render, staticRenderFns } from "./DsRating.vue?vue&type=template&id=11c31248&scoped=true"
import script from "./DsRating.vue?vue&type=script&lang=js"
export * from "./DsRating.vue?vue&type=script&lang=js"
import style0 from "./DsRating.vue?vue&type=style&index=0&id=11c31248&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c31248",
  null
  
)

export default component.exports